import React from "react";
import { Link } from "gatsby";
import Faq from "../faq";
import spareParts from "../../images/spare-parts-quote.jpg";
import fuelVouchers from "../../images/fuel-vouchers.jpg";
import carTroubles from "../../images/car-trouble-ticket.jpg";
import carInsurance from "../../images/car-insurance.jpg";
import carTyres from "../../images/tyres.jpg";
import carBatteries from "../../images/batteries.jpg";
import homeService from "../../images/home-service.jpg";
import { ActionBanner } from "./utils";

const faqData = [
  {
    id: 1,
    title: "Why should I be a Carviva Autocare customer?",
    text:
      "Carviva is a platform that connects car users and car owners to a wide network of modern OEM workshops, garages and autocare service providers across Nigeria.",
  },
  {
    id: 2,
    title: "How flexible is Carviva Autocare pricing?",
    text:
      "With Carviva, at the tap of a button on a smart phone, you can buy fuel, buy engine oil, buy airtime/Data while service appointments such as car wash, car diagnostics, lube change, wheels balancing, alignment e.t.c can be booked and paid for on-the-go and from the comfort of homes and offices.",
  },
  {
    id: 3,
    title: "How do car owners pay for service?",
    text:
      "Car owners pay cash on service delivery at service center or via prepaid card payments before visiting service center.",
  },
];

const HomeMidSection = () => {
  const BoxView = () => (
    <div className="container box-link">
      <div class="columns">
        <Link to="/quotes/" className="column box">
          <p>
            SPARE PARTS
            <br /> QUOTE
          </p>
          <div className="overlay"></div>
          <img src={spareParts} alt="Spare parts quote" />
        </Link>
        <Link to="/troubleticket/" className="column box">
          <p>
            CAR TROUBLE
            <br /> TICKET
          </p>
          <div className="overlay"></div>
          <img src={carTroubles} alt="car troubles" />
        </Link>
      </div>
      <div class="columns">
        <Link to="/battery/" className="column box">
          <p>CAR BATTERIES</p>
          <div className="overlay"></div>
          <img src={carBatteries} alt="car Batteries" />
        </Link>
        <Link to="/tyre/" className="column box">
          <p>CAR TYRES</p>
          <div className="overlay"></div>
          <img src={carTyres} alt="car tyres" />
        </Link>
      </div>
      <div class="columns">
        <Link to="/quickservice/" className="column box">
          <p>HOME SERVICE</p>
          <div className="overlay"></div>
          <img src={homeService} alt="car tyres" />
        </Link>
        {/* <div class="column box disabled">
          <p>
            CAR INSURANCE <span>COMING SOON...</span>
          </p>
          <div className="overlay"></div>
          <img src={carInsurance} alt="Spare parts quote" />
        </div> */}
        <div class="column box disabled">
          <p>
            FUEL VOUCHER <span>COMING SOON...</span>
          </p>
          <div className="overlay"></div>
          <img src={fuelVouchers} alt="car troubles" />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="home-midsection">
        <ActionBanner />
      </div>
      <BoxView />
      <div className="trust">
        <div className="container center">
          <h3>Why trust Carviva Autocare?</h3>
          <p>
            At the tap of a button, enjoy one-stop and trusted solution for car
            service, repairs, sales of spare parts and Lubricants. With our wide
            network, enjoy expert autocare near you on all car brands and older
            car models. We aim to save money for car owners by offering best
            prices on autocare service.
          </p>
        </div>
      </div>
      <Faq questions={faqData} title="Top questions from customers" />
    </>
  );
};

export default HomeMidSection;
